import styled from "styled-components";

export const AppRow = styled.div`
    display: flex;
    background: #E9EEF6;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    min-height: 100vh;
`
export const AppColumn = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFF;
    flex: 6;
    /* margin: 10px; */
    padding: 10px;
    /* border-radius: 5px; */
    min-height: 90%;
`
export const AppSideBar = styled.div`
    display: flex;
    flex-direction: column;
    /* background: #FFF; */
    flex: 1;
    margin: 10px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    min-height: 90%;
    color: white;
`

export const Account_Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    background: #FFF;
    min-height: 70vh;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media screen and (max-width: 786px){
        width: 80%;
    }
`
export const Account_Row = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    width: 80%;
`
export const Account_Column = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
`

export const Account_Form_Group = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 5px 0;
`
/* .form-group-btn{
    align-items: flex-end;
    padding-right: 24px;
} */
export const Account_Form_Label = styled.label`
    display: flex;
    width: 85%;
    padding: 5px 0;
`
export const Account_Form_Form_Control = styled.input`
    width: 85%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: #F0F0F0 solid 1px;
    outline: none;
`
export const Account_Button = styled.button`
    display: flex;
    justify-content: center;
    padding: 10px 0;
    width: 86%;
    background: #FF7F27;
    font-weight: 500;
    font-size: 16px;
    border: 0;
    outline: none;
    color: #FFF;
    border-radius: 5px;
    margin: 10px 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
`
export const Form = styled.form`
    width: 100%;
`


export const SemesterRegistration = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`