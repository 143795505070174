import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import api from "../api/api";
import { Categories } from "../pages";

export default function SubCategory() {
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [categoryid, setCategory] = useState("")
  const [categories, setCategories] = useState([])

  useEffect(()=>{
    fetchCategories()
  },[])

  const fetchCategories = async () => {
    await api.get(`/createsubcategories`).then(({data})=>{
      setCategories(data)
    })
  }


  const newCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData()
    formData.append('name', name)
    formData.append('category_id', categoryid)

    await api.post(`/subcategories`, formData).then(({data})=>{
      Swal.fire({
        icon:"success",
        text:data.message
      })
      navigate("/subcategories")
    }).catch(({response})=>{
      if(response.status===422){
        // setValidationError(response.data.errors)
      }else{
        Swal.fire({
          text:response.data.message,
          icon:"error"
        })
      }
    })
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">New Category</h4>
              <hr />
              <div className="form-wrapper">
                
                <Form onSubmit={newCategory}>
                <Row> 
                      <Col>
                        <Form.Group controlId="department">
                            <Form.Label>Category: </Form.Label>
                            <Form.Control as={'select'} value={categoryid} onChange={(e)=>setCategory(e.target.value)}>
                            <option value={-1}>--Select Category --</option>
                              {categories && categories.map((item)=>{
                                return (
                                  <option key={item.id} value={item.id}>{item.name}</option>
                                )
                              })}
                              </Form.Control>
                        </Form.Group>
                      </Col>  
                  </Row>
                  <Row> 
                      <Col>
                        <Form.Group controlId="Name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(event)=>{
                              setName(event.target.value)
                            }}
                            required
                            />
                        </Form.Group>
                      </Col>  
                  </Row>
                  
                  <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                    Create New 
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}