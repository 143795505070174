import { useNavigate } from "react-router-dom";

export class Server{
    // url = "http://localhost:8000/uploads";
    url = "https://backend.hellindafarms.com/uploads";
    navigate = useNavigate();
    
    
    setID = (name, id, frontend_url) =>{
        localStorage.setItem(name, id)
        this.navigate(frontend_url)
    }

    getID = (name) =>{
        let id = JSON.parse(localStorage.getItem(name));
        return id;
    }
    getUser = (user) =>{
        user = JSON.parse(localStorage.getItem(user));
        return user;
    }

    getData = (backend_url) =>{
        
    }

    print(){
        let sideBar = document.getElementById("side-bar")
        sideBar.style.display="none"
        window.print()
    }
}