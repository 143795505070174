import './Chart.scss';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const Chart = () => {
    const data = [
        { name: 'January',   total: 1600},
        { name: 'February',   total: 1200},
        { name: 'March',   total: 1500},
        { name: 'April',   total: 1400},
        { name: 'May',   total: 2000},
        { name: 'June',   total: 1000},
      ];
      
  return (
    <div className='chart'>
        <div className="title">Last 6 Months Revenue</div>
        <AreaChart width={730} height={250} data={data}
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
   
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <Area type="monotone" dataKey="total" stroke="#8884d8" fillOpacity={1} fill="url(#total)" />
</AreaChart>
    </div>
  )
}

export default Chart