import React , {createContext, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';


export const ShopContext = createContext();


    const ShopContextProvider = (props) => {
        const navigate = useNavigate();
       
        const setID = (name, id, url) =>{
            localStorage.setItem(name, id)
            navigate(`/${url}`)
        }

        const getID = () =>{
            let id = JSON.parse(localStorage.getItem("id"));
            return id;
        }

    const contextValues = {
        // loader,
        // resetLoader,
        setID, 
        getID, 
    };


  return (
    <ShopContext.Provider value={contextValues}>
        {props.children}
    </ShopContext.Provider>
  )
}

export default ShopContextProvider