import React from 'react'
import './home.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Widgets from '../../components/widgets/Widgets'
import Feature from '../../components/feature/Feature'
import Chart from '../../components/charts/Chart'
import BasicTable from '../../components/tables/Table'
import DataTable from '../../components/tables/DataTable'


const Home = () => {
  return (
    <div className='home'>
        {/* <Sidebar/> */}
        <div className="wrapper">
            {/* <Navbar/> */}
            <div className="homecontainer">
              <div className="widgets">
                  <Widgets type="user"/>
                  <Widgets type="order" />
                  <Widgets type="earnings" />
                  <Widgets type="balance" />
              </div>

              <div className="charts">
                <Feature/>
                <Chart/>
              </div>

              <div className="listTables">
                <h1 className="listTitle">Latest Transactions</h1>
                <DataTable/>
              </div>
            </div>
        </div>
       
    </div>
  )
}

export default Home