import { Navigate, Outlet } from 'react-router-dom'

const useAuths = ()=>{
  const user = localStorage.getItem("user")
  if(user){
    return true
  }else{
    return false
  }
}
const ProtectedRoute = () => {

  let auth = useAuths()

return auth ? <Outlet/> : <Navigate to='/login'/>
  
}
export default ProtectedRoute;