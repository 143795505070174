import { RotatingLines } from 'react-loader-spinner';
const RotatingLine = () => {
  return (
    <div style={{display:"flex", alignItems:"center", justifyContent:"center",minWidth:"100%", minHeight:"100%"}}>
        <RotatingLines style={{margin:"auto"}}
      visible={true}
      height="96"
      width="96"
      color="grey"
      strokeWidth="5"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      wrapperStyle={{}}
      wrapperClass=""
      />
      </div>
  )
}

export default RotatingLine