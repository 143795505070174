import React, { useContext, useEffect, useState } from 'react'
import api from '../api/api'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { ShopContext } from '../../components/context/AppContext'


function Details() {
    const {getID} = useContext(ShopContext)
    


    useEffect(()=>{
      let user = getID();
        
    }, [])

    const [userinfo, setUserInfo] = useState({ }); 



      const handleSubmit = (e) =>{
        e.preventDefault()
      
        api.post("/courseregistraions", userinfo).then(({data})=>{
            window.location = "/semester-registration"
        }).catch(({response})=>{
          Swal.fire({
            text:response.data.message,
            icon:"error"
          })
        })
      }
  return (
    <div>
      
        

    </div>
  )
}

export default Details