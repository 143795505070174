import './widgets.scss'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { PaymentTwoTone } from '@mui/icons-material';

const Widgets = ({ type }) => {
  let data;
  switch(type){
    case "user": data = {
      title: "USER",
      link: "List All Users",
      isMoney: false,
      icon: (<PersonOutlineIcon className='icon' style={{background:"orange", color:"#FFF"}}/>)
    }
    break;
    case "order": data = {
      title: "ORDERS",
      link: "List All Orders",
      isMoney: false,
      icon: (<ShoppingCartOutlinedIcon className='icon' style={{background:"pink", color:"#FFF"}}/>)
    }
    break;
    case "earnings": data = {
      title: "EARNINGS",
      link: "List All Earnings",
      isMoney: true,
      icon: (<AccountBalanceWalletOutlinedIcon className='icon' style={{background:"purple", color:"#FFF"}} />)
    }
    break;
    case "balance": data = {
      title: "BALANCE",
      link: "List All Balance",
      isMoney: true,
      icon: (<PaymentTwoTone className='icon' style={{background:"magenta", color:"#FFF"}}/>)
    }
    break;
    default:
      break;
  }
  
  return (
    <div className='widget'>
        <div className="left">
            <span className="title">{data.title}</span>
            <span className="counter">847476</span>
            <span className="link">{data.link}</span>
        </div>
        <div className="right">
            <span className="percentage positive">
                <KeyboardArrowUpOutlinedIcon/>
                20%</span>
                {data.icon}
        </div>
    </div>
  )
}

export default Widgets