import React,{useEffect, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Swal from 'sweetalert2';
import api from '../api/api';

const EditState = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [description, setDescription] = useState("")
  const [categoryid, setCategory] = useState("")
  const [subcategoryid, setSubCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [subcategories, setSubCategories] = useState([])
  const [selectedFile, setSelectedFile] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  useEffect(()=>{
    fetchProducts()
  },[id])

  const fetchProducts = async () => {
    await api.get(`products/${id}`).then(({data})=>{
      const { name, price, description, category_id, subcategory_id} = data.product
      setName(name)
      setPrice(price)
      setDescription(description)
      setCategory(category_id)
      setSubCategory(subcategory_id)
      setCategories(data.categories)
      setSubCategories(data.subcategories)
    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
  
  const updateProducts = async(event) => {
      event.preventDefault();
      event.persist();
    const formData = new FormData()
    formData.append('_method', 'PATCH');
    formData.append('id', id);
    formData.append('name', name)
    formData.append('price', price)
    formData.append('description', description)
    formData.append('category_id', categoryid)
    formData.append('subcategory_id', subcategoryid)
   
    if(selectedFile!==null){
      formData.append("file", selectedFile);
    }
   
      await api.post(`/products/${id}`, formData)
      .then(({data}) => {
        Swal.fire({
          text:data.message,
          icon:"success"
        })
        navigate(`/products`);
       })
    }

return (
  <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">New Category</h4>
              <hr />
              <div className="form-wrapper">
                
                <Form onSubmit={updateProducts}>
                <Row> 
                      <Col>
                        <Form.Group controlId="department">
                            <Form.Label>Category: </Form.Label>
                            <Form.Control as={'select'} value={categoryid} onChange={(e)=>setCategory(e.target.value)}>
                            <option value={-1}>--Select Category --</option>
                              {categories && categories.map((item)=>{
                                return (
                                  <option key={item.id} value={item.id}>{item.name}</option>
                                )
                              })}
                              </Form.Control>
                        </Form.Group>
                      </Col>  
                  </Row>
                <Row> 
                      <Col>
                        <Form.Group controlId="department">
                            <Form.Label>Sub Category: </Form.Label>
                            <Form.Control as={'select'} value={subcategoryid} onChange={(e)=>setSubCategory(e.target.value)}>
                            <option value={-1}>--Select sub Category --</option>
                              {subcategories && subcategories.map((item)=>{
                                return (
                                  <option key={item.id} value={item.id}>{item.name}</option>
                                )
                              })}
                              </Form.Control>
                        </Form.Group>
                      </Col>  
                  </Row>
               
                  <Row> 
                      <Col>
                        <Form.Group controlId="Name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={name} onChange={(event)=>{
                              setName(event.target.value)
                            }}
                            required
                            />
                        </Form.Group>
                      </Col>  
                  </Row>
                  <Row> 
                      <Col>
                        <Form.Group controlId="Description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" value={description} onChange={(event)=>{
                              setDescription(event.target.value)
                            }}
                            required
                            />
                        </Form.Group>
                      </Col>  
                  </Row>
                  <Row> 
                      <Col>
                        <Form.Group controlId="Price">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="number" value={price} onChange={(event)=>{
                              setPrice(event.target.value)
                            }}
                            required
                            />
                        </Form.Group>
                      </Col>  
                  </Row>
                  <Row> 
                      <Col>
                        <Form.Group controlId="Name">
                            {/* <Form.Label>Product Image</Form.Label> */}
                            <input type="file" onChange={changeHandler} name="file"  accept=".jpg, .png, .jpg"/>
                        </Form.Group>
                      </Col>  
                  </Row>
                  
                  <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                    Update Product 
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
);
};

export default EditState;