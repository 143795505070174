import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import api from '../api/api';
import "../assets/responsive-table.css";
import { ShopContext } from '../../components/context/AppContext';

export default function List() {
  const {getID, setID} = useContext(ShopContext);

    const [users, setUsers] = useState([])
    useEffect(()=>{
        fetchUsers() 
    },[])

    const fetchUsers = async () => {
        await api.get(`/other-users/Investor`).then(({data})=>{
            setUsers(data)
        })
    }

    return (

      <div className="row justify-content-center mt-3" style={{maxWidth:"100%"}}>
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">Investors List [{users.length}]</div>
                <div className="card-body">
                    {/* <Link to={"/newuser"} className="btn btn-success btn-sm my-2"><i className="bi bi-plus-circle"></i> New User</Link> */}
                    <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Type</th>
                            <th scope="col">Date</th>
                            
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                            
                        {
                        users.length > 0 && (
                            users.map((row, key)=>(
                                <tr key={key}>
                                <td>{row.surname} {row.firstname} {row.othernames}</td>
                                <td>{row.email}</td>
                                <td>{row.phone}</td>
                                <td>{row.usertype}</td>
                                <td>{row.created_at.substr(0,10)}</td>
                                <td>
                                        <span onClick={()=>setID( "id", row.id, "userdetails")} className="btn btn-warning btn-sm"><i className="bi bi-eye"></i> View</span>
    
                                        <span onClick={()=>setID( "id", row.id, "edituser")} className="btn btn-primary btn-sm"><i className="bi bi-pencil-square"></i> Edit</span>   
     
                                        {/* <button type="submit" className="btn btn-danger btn-sm"  onClick={()=>deleteApplication(row.id)}><i className="bi bi-trash"></i> Delete</button>  */}
                                        {/* <button type="submit" className="btn btn-danger btn-sm"  onClick={()=>approveApplication(row.id)}><i className="bi bi-tick"></i> Approve</button>   */}
                                </td>
                                </tr>
                                    ))
                                    )
                                }
                        </tbody>
                      </table>
                </div>
            </div>
        </div>    
    </div>
    )
}