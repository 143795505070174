import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RotatingLine from '../../components/Loaders/RotatingLine';
import Swal from 'sweetalert2'
import api from '../api/api';
import "../assets/responsive-table.css";
import { DataGrid } from '@mui/x-data-grid';
import "./states.scss";

const columns = [
  { field: 'id', headerName: 'ID', width: 20 },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'email', headerName: 'Email', width: 300 },
  { field: 'phone', headerName: 'Phone', width: 120 },
  { field: 'message', headerName: 'Message', width: 430 },
]
const actionColumns = [
  { field: 'action', headerName: 'Actions', width: 100,
renderCell:(params)=>{
    return(
        <div className='actions'>
        <span className='viewButton'>view</span>
        {/* <span onClick={()=>handleDelete(params.row.id)}>Delete</span> */}
        </div>
    )
}
},
]
const handleDelete = async(id)=>{
    const isConfirm = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        return result.isConfirmed
      });

      if(!isConfirm){
        return;
      }

      await api.delete(`contacts/${id}`).then(({data})=>{
        Swal.fire({
            icon:"success",
            text:data.message
        })
        
      }).catch(({response:{data}})=>{
        Swal.fire({
            text:data.message,
            icon:"error"
        })
      })
}

export default function List() {

    const [states, setStates] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(()=>{
        fetchStates() 
        setIsLoading(false);
    },[])

    const fetchStates = async () => {
        await api.get(`/contacts`).then(({data})=>{
            setStates(data)
        })
    }

    return (
      <div className="container">
          <div className="row">
            {
                isLoading ?
                <RotatingLine/>
                :
                <>
                <div className='col-12'>
                <span className='btn btn-primary mb-2 float-start'>
                    [Messages:  {states.length}]
                </span>
               
            </div>
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={states}
                        columns={columns.concat(actionColumns)}
                        initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        },
                        }}
                        pageSizeOptions={[20, 40]}
                        checkboxSelection
                    />
                </div>
                </>
            }
          </div>
      </div>
    )
}