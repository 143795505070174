import React,{useEffect, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import api from '../api/api';

const EditState = () => {
    const { id } = useParams()
    const [state, setState] = useState();
    const navigate = useNavigate();

  useEffect(()=>{
    fetchStates()
  },[id])

  const fetchStates = async () => {
    await api.get(`categories/${id}`).then(({data})=>{
      const { name } = data.category;
      setState(name)
    }).catch(({response})=>{
      // Swal.fire({
      //   text:data.message,
      //   icon:"error"
      // })
    })
  }
  
  const updateState = async(event) => {
      event.preventDefault();
      event.persist();
    const formData = new FormData()
    formData.append('_method', 'PATCH');
    formData.append('id', id);
    formData.append('name', state)
      await api.post(`/categories/${id}`, formData)
      .then(res => {
        navigate(`/categories`);
       })
    }

return (
  <form className="editForm" onSubmit={updateState}>
    <h2> Edit Form </h2>
    <label htmlFor="_name">Name</label>
    <input
      type="text"
      name="name"
      value={state}
      onChange={(e)=>setState(e.target.value)}
      autoComplete="off"
      required
    />
    
    <input type="submit" value="update" />
  </form>
);
};

export default EditState;