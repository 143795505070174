import  MoreVertIcon  from '@mui/icons-material/MoreVertOutlined';
import  './Feature.scss'
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

const Feature = () => {
  return (
    <div className='feature'>
        <div className="top">
            <h1 className="title"> Total Revenue </h1>
            <MoreVertIcon fontSize='small'/>
        </div>
        <div className="bottom">
            <div className="featuredChart">
                <CircularProgressbar value={70} text='70%' strokeWidth={4}/>
            </div>
            <p className="title">
                Total Sales made today
            </p>
            <p className="amount">
               NGN 420
            </p>
            <p className="description">
               Previous transaction processing. Last payment made not includes
            </p>
        </div>
    </div>
  )
}

export default Feature