import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import api from '../api/api';
import "../assets/responsive-table.css";
import { Server } from '../api/server';

export default function List() {
    let server = new Server()

    const [items, setItems] = useState([])
    useEffect(()=>{
        fetchItems() 
    },[])

    const fetchItems = async () => {
        await api.get(`/orders`).then(({data})=>{
            setItems(data)
        })
    }

    return (
      <div className="container">
          <div className="row">
            <div className="col-12">
                <div className="card card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0 text-center">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Qty</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.length > 0 && (
                                        items.map((row, key)=>(
                                            <tr key={key}>
                                                <td> <img src={`${server.url}/${row.imageurl}`} height={40}/></td>
                                                <td>{row.name}</td>
                                                <td>{row.quantity}</td>
                                                <td>{row.total}</td>
                                                <td>{row.created_at}</td>
                                                {/* <td>
                                                    <Link to={`/products-edit/${row.id}`} className='btn btn-success me-2'>
                                                        Edit
                                                    </Link>
                                                    <Button variant="danger" onClick={()=>deleteItems(row.id)}>
                                                        Delete
                                                    </Button>
                                                </td> */}
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
      </div>
    )
}