import "./Navbar.scss"
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SearchOutLinedIcon from '@mui/icons-material/SearchOutlined';
import DarkModeLinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

function Navbar() {
  return (
    <div className='navbar'>
        <div className="search">
          <input type="text" placeholder="Search here" />
          <SearchOutLinedIcon/>
        </div>
        <div className="items">
          <div className="item">
            <DarkModeLinedIcon className="icon"/>
          </div>
          <div className="item">
            <NotificationAddOutlinedIcon className="icon"/>
            <span className="counter">1</span>
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon"/>
          </div>
          <div className="item">
            <ListAltOutlinedIcon className="icon"/>
          </div>
          <div className="item">
            <ChatBubbleOutlineIcon className="icon"/>
            <span className="counter">2</span>
          </div>
          <div className="item">
            <LanguageOutlinedIcon className="icon"/>
          </div>
        </div>
    </div>
  )
}

export default Navbar