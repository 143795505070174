import React from 'react'
import { useParams } from 'react-router-dom'

const Details = () => {
    const { slug } = useParams()
    console.log(slug)
  return (
    <div>
        {slug}
    </div>
  )
}

export default Details