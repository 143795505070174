import React from 'react'
import "./Loaders.css"

export const MainLoader = () => {
  return (
    <div className='loader'>
       
    </div>
  )
}
