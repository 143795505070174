import React, { useState } from 'react'
import { Account_Button, Account_Column, Account_Container, Account_Form_Form_Control, Account_Form_Group, Account_Form_Label, Form } from './layout'
import api from '../api/api'
import SweetAlert2 from 'react-sweetalert2';
import "../../components/Loaders/Loaders.css"
import { MainLoader } from '../../components/Loaders/MainLoader';
import logo from "../../images/logo1.png"

function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const [showError, setShowError] = useState(false)

    const handleLogin = (e) =>{
        e.preventDefault()
        const formData = new FormData()
        formData.append('email', email)
        formData.append('password', password)

        e.preventDefault();
        setLoader(true);
      api.post("/login", formData).then(({data})=>{
        if(data.message === "Success"){
          localStorage.setItem("user", JSON.stringify(data.user))
          setSwalProps({
            show: true,
            title: 'Success',
            text: 'Login succeeded',
        });
          window.location = "/";
        }
        setLoader(false);
       
      }).catch(({response})=>{
        setShowError(true)
        setSwalProps({
            show: true,
            title: 'Warning',
            text: 'Login failed, incorrect username or password',
        });
        setLoader(false);
      })
      }

  return (
    
    <Account_Container style={{display:"flex",alignItems:"center", justifyContent:"center",flexDirection:"column", margin:"auto"}}>
        <div class="account-row" style={{paddingTop:"50px",display:"flex",alignItems:"center", justifyContent:"center",flexDirection:"column"}}>
            <h3>ADMIN LOGIN HERE</h3>
            <img src={logo} width={120}/>
        </div>
        <Account_Column>
           <Form >
                <Account_Form_Group className="account-form-group">
                    <Account_Form_Label for="name" className="form-label">Username</Account_Form_Label>
                    <Account_Form_Form_Control type="text" 
                    className="account-form-control" onChange={(e)=>setEmail(e.target.value)}/>
                </Account_Form_Group>
                <Account_Form_Group className="account-form-group">
                    <Account_Form_Label for="name" className="form-label">Password</Account_Form_Label>
                    <Account_Form_Form_Control 
                     type={
                        showPassword ? "text" : "password"
                      }
                    className="account-form-control" 
                    onChange={(e)=>setPassword(e.target.value)}/>
                     <span>
              {showPassword ? <i className="fa fa-eye" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i> 
              :  <i class="fa fa-eye-slash" aria-hidden="true" onClick={()=>setShowPassword(!showPassword)}></i>
              }
            </span>
            {showError && <span style={{color:"red",paddingTop:"20px"}}>Username or password incorrect</span>}
                </Account_Form_Group>
                <Account_Form_Group className="account-form-group form-group-btn">
                    <Account_Button  className="account-btn" onClick={handleLogin}>
                    {loader ?  <span style={{display:"flex", alignItems:"center", justifyContent:"center"}} ><MainLoader/> <span>Processing </span></span>
                    :"Login"
                    }
                    </Account_Button>
                </Account_Form_Group>
           </Form>
        </Account_Column>
        <SweetAlert2 {...swalProps} />
    </Account_Container>
  )
}

export default Login