import './Sidebar.scss'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import ListAltOutlined from '@mui/icons-material/ListAltOutlined';
import { Link } from 'react-router-dom'
import { useState } from 'react';
import styled from 'styled-components';
import logo from "../../images/logo1.png"


const Div = styled.div`
  flex: ${(prop) => prop.active?"1":""};
  z-index: 1;
  background-color: ${(prop) => prop.active?"#FFF":"#333333"};
  transition: 0.5s;
`;
const Span = styled.span`
  display: ${(prop) => prop.active?"flex":"none"};
`
function Sidebar() {
  const [isOpened, setIsOpened] = useState(true);

  return (
    <Div className='sidebar' active={isOpened}>
        <div className="top">
            <Span className="logo" active={isOpened}>
              
            <img src={logo} alt="Logo" height={50}/>  
            </Span>
            <ListAltOutlined style={{color:"#013B70"}} onClick={() => setIsOpened(!isOpened)} />
        </div>
        <hr />
        <div className={`center ${isOpened}&&"active`}>
           <ul>
            <li><GridViewOutlinedIcon className='icon'/><Span active={isOpened}> 
              <Link to={"/"} className='link'>Dashboard</Link>
              </Span></li>
              <li><InsertChartOutlinedTwoToneIcon className='icon'/> <Span active={isOpened}> <Link to={"/branches"} className='link'> Branches </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon' /> <Span active={isOpened}> <Link to={"/categories"} className='link'> Category </Link></Span>  </li>
            <li><InsertChartOutlinedTwoToneIcon className='icon'/> <Span active={isOpened}> <Link to={"/subcategories"} className='link'> Subcategory </Link></Span></li>
            <li><InsertChartOutlinedTwoToneIcon className='icon'/> <Span active={isOpened}> <Link to={"/products"} className='link'> Products </Link></Span></li>
            
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}>  <Link to={"/sessions"}  className='link'> Stocks </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}><Link to={"/orders"} className='link'> Orders </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}><Link to={"/users"} className='link'> Users </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}><Link to={"/staff"} className='link'> Staff </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}><Link to={"/investors"} className='link'> Investors </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}><Link to={"/riders"} className='link'> Riders </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}><Link to={"/messages"} className='link'> Messages </Link></Span></li>
            <li><PermIdentityOutlinedIcon className='icon'/> <Span active={isOpened}><Link to={"https://webmail.hellindafarms.com/"} className='link'> Emails </Link></Span></li>
            
            <li onClick={()=>{
              localStorage.removeItem("user")
              window.location = "/login"
            }}><InsertChartOutlinedTwoToneIcon className='icon'/><Span active={isOpened}>Logout</Span></li>
           </ul>
        </div>
        <div className="bottom">
          <div className="colorOption"></div>
          <div className="colorOption"></div>
        </div>
    </Div>
  )
}

export default Sidebar